import './App.css';
import NavigationBar from './NavigationBar';
import PricingPage from './PricingPage';
import InventoryPage from './InventoryPage';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './HomePage';
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import CombineSOPage from "./CombineSOPage"
// import { getAnalytics } from "firebase/analytics";
import { useState } from 'react';
import LoginPage from './LoginPage';
import DecoManagerPage from './DecoManagerPage';
import ImportManagerPage from './ImportManagerPage';
import POManagerPage from './POManagerPage';
import CreateBulkPage from './CreateBulkPOPage';
// import SanmarPage from './SanmarPage';

const firebaseConfig = {
  apiKey: "AIzaSyBxaNFkeFwjCf9kTUo-3Cley_15WDCaGJ0",
  authDomain: "redtech-myca.firebaseapp.com",
  databaseURL: "https://redtech-myca.firebaseio.com",
  projectId: "redtech-myca",
  storageBucket: "redtech-myca.appspot.com",
  messagingSenderId: "464276308698",
  appId: "1:464276308698:web:18a2ddd0991e8abc409be3",
  measurementId: "G-XTDQC1JFT7"
};

function App() {
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const db = getFirestore(app);
  const [user, setUser] = useState(null);
  
  onAuthStateChanged(auth, (user) => {
    setUser(user);
  });

  if (user === null) {
    return <LoginPage />;
  }

  return (
    <BrowserRouter>
      <NavigationBar/>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/home" element={<HomePage />} />
        {/* <Route path="/pricing" element={<PricingPage />} /> */}
        {/* <Route path="/inventory" element={<InventoryPage db={db} />} /> */}
        {/* <Route path="/salesorders" element={<CombineSOPage db={db} />} /> */}
        {/* <Route path="/decomanager" element={<DecoManagerPage db={db} />} /> */}
        {/* <Route path="/importmanager" element={<ImportManagerPage db={db} />} /> */}
        <Route path="/pomanager" element={<POManagerPage db={db} />} />
        {/* <Route path="/createBulkPO" element={<CreateBulkPage db={db} />} /> */}
        {/* <Route path="/sanmar" element={<SanmarPage />} /> */}
    </Routes>
  </BrowserRouter>
  );
}

export default App;
