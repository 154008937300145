import { Button, Container, TextField, Card, CardContent, Stack, Typography, Alert } from "@mui/material";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";

function LoginPage() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);

    const handleEmailUpdate = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordUpdate = (event) => {
        setPassword(event.target.value);
    };

    const submit = async () => {
        const auth = getAuth();
        try {
            var cred = await signInWithEmailAndPassword(auth, email, password);
        } catch (e) {
            console.log(e);
            setError(e.toString());
        }
        console.log(cred);
    }

    return (
        <Container sx={{height: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
            <Typography gutterBottom textAlign="center" variant="h2" component="div">
                Inksoft Tools
            </Typography>
            <Card margin={"auto"}>
                <CardContent>
                    <Typography gutterBottom textAlign="center" variant="h5" component="div">
                        Login
                    </Typography>
                    <Stack
                        direction="row"
                        alignItems="center"
                        gap={1}
                        sx={{padding: "16px"}}
                    >
                        <TextField 
                            id="outlined-basic" 
                            label="Email" 
                            variant="outlined" 
                            onChange={handleEmailUpdate} 
                            autoComplete="email"
                        />
                        <TextField 
                            id="outlined-basic" 
                            label="Password" 
                            variant="outlined" 
                            onChange={handlePasswordUpdate}
                            type="password"
                            autoComplete="current-password"
                        />
                        <Button variant="contained" onClick={submit}>Login</Button>
                    </Stack>
                </CardContent>
            </Card>
            {error !== null &&
                <Alert sx={{margin: "16px"}} variant="outlined" severity="error">
                    {error}
                </Alert>
            }
            <Typography padding={1} textAlign="center" variant="body1" component="div">
                Created by Logotology
            </Typography>
        </Container>
    );
}

export default LoginPage;