import { Chip, Container, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import axios from "axios";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { getBulkPO, getPOs, getValidatedPO } from "./managers/POManager";

function CreateBulkPOPage(props) {
    let [searchParams, setSearchParams] = useSearchParams();
    let [validatedPO, setValidatedPO] = React.useState({"items": []});

    React.useEffect(() => {
        const getData = async () => {
            console.log("get bulk po");
            console.log(searchParams);
            // Getting POs
            const syncorePOs = JSON.parse(searchParams.get("syncorePOs"));
            console.log(syncorePOs);
            let pos = [];
            for (let poNum of syncorePOs) {
                pos.push(...(await getPOs(poNum)));
            }

            // Combine POs
            const bulkPO = await getBulkPO(pos);
            
            // Validate PO
            const validatedPO = await getValidatedPO(bulkPO);
            console.log(validatedPO);
            setValidatedPO(validatedPO);
        }
        getData();
    }, [searchParams]);

    return (
        <Container>
            <Stack
                direction='column'
                alignItems='center'
                gap={1}
                margin={2}
            >
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Sku</TableCell>
                                <TableCell>Product Name</TableCell>
                                <TableCell>Color</TableCell>
                                <TableCell>Size</TableCell>
                                <TableCell align="right">Price</TableCell>
                                <TableCell align="right">Quantity</TableCell>
                                <TableCell align="right">Available</TableCell>
                                <TableCell>POs</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {validatedPO.items.map((item, idx) => (
                                <TableRow
                                    key={idx}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {item.sku}
                                    </TableCell>
                                    <TableCell>{item.productName}</TableCell>
                                    <TableCell>{item.color}</TableCell>
                                    <TableCell>{item.size}</TableCell>
                                    <TableCell align="right">
                                        <Typography variant={'inherit'}>${item.price.toFixed(2)}</Typography>
                                        <Typography variant={'inherit'}>${item.validatedSize?.prices[0]?.price?.toFixed(2)}</Typography>
                                    </TableCell>
                                    <TableCell align="right">{item.quantity}</TableCell>
                                    <TableCell align="right">{item?.validatedSize?.quantityAvailable ?? "N/A"}</TableCell>
                                    <TableCell>
                                    <Stack direction="row" spacing={1}>
                                        {
                                            item.pos.map((po, idx) => (
                                                <Tooltip key={idx} title={po.quantity}>
                                                    <Chip label={`${po.jobNumber}-${po.poNumber}`} component={"a"} onClick={() => {}} href={`https://www.ateasesystems.net/PurchaseOrder/Details/${po.poNumber}?jobId=${po.jobNumber}`} target={"_blank"}/>
                                                </Tooltip>
                                            ))
                                        }
                                    </Stack>
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell colSpan={4} />
                                <TableCell align="right">${validatedPO.items.reduce((acc, i) => acc += i.price * i.quantity, 0).toFixed(2)}</TableCell>
                                <TableCell align="right">{validatedPO.items.reduce((acc, i) => acc += i.quantity, 0)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        </Container>
    );
}

export default CreateBulkPOPage;