import axios from "axios";
import React from "react";
import { Container, Stack, List, ListItem, Checkbox, ListItemButton, ListItemText, ListItemIcon } from "@mui/material";
import { getAuth } from "firebase/auth";

function CombineSOPage(props) {
    // let [jobId, setJobId] = React.useState("")
    let [salesOrders, setSalesOrders] = React.useState([]);
    let [combinedData, setCombinedData] = React.useState([]);
    let [checked, setChecked] = React.useState([]);
    let [error, setError] = React.useState(null);


    const getSalesOrders = async () => {
        try {
            const auth = getAuth();
            const token = await auth.currentUser.getIdToken();
            let response = await axios.get(
                `http://127.0.0.1:8080/getJobs`,
                {
                    params: {
                        "storeId": 868,
                        "status": "Pending",
                        "includeSO": "true"
                    },
                    headers: {
                        "Authorization": `Bearer ${token}`
                    },
                }
            );
            let data = response.data;
            console.log(data);
            setSalesOrders(data["data"]["salesOrders"])
        } catch (e) {
            console.log(e);
            setError(e.toString());
        }
    }

    React.useEffect(() => {
        if (salesOrders.length === 0) {
            getSalesOrders();
        }
    }, [salesOrders]);

    const submitCombineRequest = async () => {
        try {
            let submitSO = [];
            for (let so of salesOrders) {
                if (checked.includes(so["job_number"] + "-" + so["number"])) {
                    submitSO.push(so);
                }
            }
            console.log(submitSO);
            const auth = getAuth();
            const token = await auth.currentUser.getIdToken();
            let json = JSON.stringify({"salesOrders": submitSO});
            let response = await axios.post(
                `http://127.0.0.1:8080/combineSalesOrders`,
                json,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            let data = response.data;
            console.log(data);
            setCombinedData(data["data"])
        } catch (e) {
            console.log(e);
            setError(e.toString());
        }
    }

    const handleToggle = (value) => () => {
        console.log(value);
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        console.log(newChecked);
        setChecked(newChecked);
    };

    React.useEffect(() => {
        submitCombineRequest();
    }, [checked]);

    return (
        <Container>
            <Stack
                direction='column'
                alignItems='center'
            >
                {/* <Card>
                    <TextField id="filled-basic" label="Filled" variant="filled" onChange={(event) => setJobId(event.target.value)}/>
                    <Button variant="contained" onClick={submitJobRequest}>Login</Button>
                </Card> */}
                <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    {salesOrders.map((so) => {
                        const name = so["job_number"] + "-" + so["number"];
                        const labelId = `checkbox-list-secondary-label-${name}`;
                        return (
                            <ListItem
                                key={name}
                                disablePadding
                            >
                                <ListItemButton role={undefined} onClick={handleToggle(name)} dense>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={checked.indexOf(name) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={`${name}`} />
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </List>
                <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    {combinedData.map((line) => {
                        const labelId = `checkbox-list-secondary-label-${line["line_id"]}`;
                        return (
                            <ListItem
                                key={line["line_id"]}
                                secondaryAction={
                                    <ListItemText>{line["quantity"]}</ListItemText>
                                }
                            >
                                <ListItemText id={labelId} primary={`${line["type"] + ": " + line["description"]}`} />
                            </ListItem>
                        );
                    })}
                </List>
            </Stack>
        </Container>
    );
}

export default CombineSOPage;