import { CardContent, Container, Typography, Card, Button, Stack } from "@mui/material";
import { Link } from "react-router-dom";

function HomePage() {
    return (
        <Container>
            <Stack
                direction="column"
                alignItems="center"
            >
                {/* <Card sx={{margin: "16px", width: "500px"}}>
                    <CardContent
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <Typography gutterBottom textAlign="center" variant="h5" component="div">
                            Pricing Tool
                        </Typography>
                        <Typography gutterBottom textAlign="center" variant="body1" component="div">
                            Edit supplier cost and price for all products in a store
                        </Typography>
                        <Button component={Link} variant="contained" to={"/pricing"}>View Pricing Tool</Button>
                    </CardContent>
                </Card> */}
                {/* <Card sx={{margin: "16px", width: "500px"}}>
                    <CardContent
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <Typography gutterBottom textAlign="center" variant="h5" component="div">
                            Inventory Tool
                        </Typography>
                        <Typography gutterBottom textAlign="center" variant="body1" component="div">
                            Edit product inventory and set up auto sync from suppliers
                        </Typography>
                        <Button component={Link} variant="contained" to={"/inventory"}>View Inventory Tool</Button>
                    </CardContent>
                </Card> */}
                {/* <Card sx={{margin: "16px", width: "500px"}}>
                    <CardContent
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <Typography gutterBottom textAlign="center" variant="h5" component="div">
                            Combine Sales Order Tool
                        </Typography>
                        <Typography gutterBottom textAlign="center" variant="body1" component="div">
                            Combine Syncore sales orders
                        </Typography>
                        <Button component={Link} variant="contained" to={"/salesorders"}>View Combine Sales Order Tool</Button>
                    </CardContent>
                </Card> */}
                {/* <Card sx={{margin: "16px", width: "500px"}}>
                    <CardContent
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <Typography gutterBottom textAlign="center" variant="h5" component="div">
                            Decoration Management Tool
                        </Typography>
                        <Typography gutterBottom textAlign="center" variant="body1" component="div">
                            Manage Decoration Information for Inksoft Stores
                        </Typography>
                        <Button component={Link} variant="contained" to={"/decomanager"}>View Decoration Management Tool</Button>
                    </CardContent>
                </Card>
                <Card sx={{margin: "16px", width: "500px"}}>
                    <CardContent
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <Typography gutterBottom textAlign="center" variant="h5" component="div">
                            Syncore Order Import Manager
                        </Typography>
                        <Typography gutterBottom textAlign="center" variant="body1" component="div">
                            Manage Inksoft Orders to Import into Syncore
                        </Typography>
                        <Button component={Link} variant="contained" to={"/importmanager"}>View Import Manager</Button>
                    </CardContent>
                </Card> */}
                <Card sx={{margin: "16px", width: "500px"}}>
                    <CardContent
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <Typography gutterBottom textAlign="center" variant="h5" component="div">
                            Syncore PO Manager
                        </Typography>
                        <Typography gutterBottom textAlign="center" variant="body1" component="div">
                            Manage Syncore POs
                        </Typography>
                        <Button component={Link} variant="contained" to={"/pomanager"}>View PO Manager</Button>
                    </CardContent>
                </Card>
                <Typography margin={4}>Version: 1.6.3</Typography>
            </Stack>
        </Container>
    );
}

export default HomePage;