import axios from "axios";
import React from "react";
import { Container, Stack, Card, Paper, Table, TableRow, List, ListItem, Checkbox, ListItemButton, ListItemText, ListItemIcon, TableCell, TableBody, TextField, Button, TableContainer, TableHead, Typography, CardContent, Box, IconButton, Alert, Tooltip, Chip } from "@mui/material";
import { arrayRemove, arrayUnion, collection, doc, onSnapshot, query, updateDoc, where } from "firebase/firestore";
import { Check, ContentCopy, CopyAll, ExitToApp, Refresh } from "@mui/icons-material";
import { red } from "@mui/material/colors";
import { getAuth } from "firebase/auth";
import { createSearchParams, useNavigate } from "react-router-dom";

function POManagerPage(props) {
    let { db } = props;
    let [input, setInput] = React.useState("");
    let [docData, setDocData] = React.useState({"unchecked": [], "pos": []});
    let [purchaseLists, setPurchaseLists] = React.useState([]);
    let [error, setError] = React.useState(null);
    let [myCAPOs, setCAPOs] = React.useState([]);
    const navigate = useNavigate();

    React.useEffect(() => {
        const unsubscribe = onSnapshot(doc(db, "globalData", "poManager"), (doc) => {
            let data = doc.data();
            setDocData(data);
        }, (error) => console.log(error));
        return unsubscribe;
    }, [db]);

    React.useEffect(() => {
        let today = new Date(Date.now());
        today.setHours(0, 0, 0, 0);
        const q = query(collection(db, "boxes"), where("createDate", ">=", today));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            console.log(snapshot.docs.map(poDoc => poDoc.data()));
            setCAPOs(snapshot.docs.map(poDoc => poDoc.data()).filter(po => po.status === "Ordered"));
        }, (error) => console.log(error));
        return unsubscribe;
    }, [db]);

    const addPO = async (id) => {
        let jobId = id;
        let poNum = null;
        if (id.includes("-")) {
            [jobId, poNum] = id.split("-").map(p => parseInt(p));
        }
        const auth = getAuth();
        const token = await auth.currentUser.getIdToken();
        let response = await axios.get(
            `https://syncore.ltcloud.io/getPurchaseOrders`,
            {
                params: {
                    "jobId": jobId,
                },
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
        );
        let data = response.data;
        let getPOs = data["data"]["purchaseOrders"].filter(po => po.supplier.class !== "In House Production");
        console.log(getPOs);
        if (poNum !== null) {
            getPOs = getPOs.filter(po => po.number === poNum);
        }
        console.log(getPOs);
        await updateDoc(doc(db, "globalData", "poManager"), {"pos": arrayUnion(...getPOs)});
    }

    const validatePO = async (po) => {
        navigate({
            pathname: "/createBulkPO",
            search: createSearchParams({
                syncorePOs: JSON.stringify(docData.pos.filter((po) => !docData.unchecked.includes(po["job_number"] + "-" + po["number"])).map((po) => po.job_number + "-" + po.number))
            }).toString()
        });
    }

    const refreshPO = async (value) => {
        await removePO(value);
        await addPO(value);
    }

    const removePO = async (value) => {
        let po = docData.pos.filter(po => (po.job_number + "-" + po.number) === value)[0];
        await updateDoc(doc(db, "globalData", "poManager"), {"pos": arrayRemove(po)});
    }

    const clearPOs = async () => {
        await updateDoc(doc(db, "globalData", "poManager"), {"pos": [], "unchecked": []});
    }

    const handleToggle = (value) => () => {
        console.log("check");
        const currentIndex = docData.unchecked.indexOf(value);
        const newUnhecked = [...docData.unchecked];

        if (currentIndex === -1) {
            newUnhecked.push(value);
        } else {
            newUnhecked.splice(currentIndex, 1);
        }
        updateDoc(doc(db, "globalData", "poManager"), {"unchecked": newUnhecked});
    };

    React.useEffect(() => {
        console.log("submit");
        const submitCombineRequest = async () => {
            try {
                let submitPO = [];
                for (let po of docData.pos) {
                    if (!docData.unchecked.includes(po["job_number"] + "-" + po["number"])) {
                        submitPO.push(po);
                    }
                }
                console.log(submitPO);
                let json = JSON.stringify({ "purchaseOrders": submitPO });
                const auth = getAuth();
                const token = await auth.currentUser.getIdToken();
                let response = await axios.post(
                    `https://syncore.ltcloud.io/getPurchaseLists`,
                    json,
                    {
                        headers: {
                            "Authorization": `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    }
                );
                let data = response.data;
                console.log(data["data"]["purchaseLists"]);
                setPurchaseLists(data["data"]["purchaseLists"])
                setError(null);
            } catch (e) {
                console.log(e);
                setError(e.toString());
            }
        }    
        submitCombineRequest();
    }, [docData]);

    const keyPress = (e) => {
        if (e.keyCode === 13) {
            addPO(input);
            setInput("");
        }
    }

    const copyToClipboard = (pl) => {
        let cb = "Sku\tProduct Name\tColor\tSize\tPrice\tQuantity\tPOs";
        for (let item of pl.items) {
            cb += `\n${item.sku}\t${item.productName}\t${item.color}\t${item.size}\t${item.price}\t${item.quantity}\t${item.pos.map(po => `${po.jobNumber}-${po.poNumber}`).join(",")}`
        }
        navigator.clipboard.writeText(cb);
    }

    return (
        <Container>
            <Stack
                direction='column'
                alignItems='center'
                gap={1}
                margin={2}
            >
                <Card sx={{minWidth: "400px"}}>
                    <CardContent>
                        <Typography variant="h6">Add Purchase Order</Typography>
                        <Stack
                            direction={'row'}
                            gap={1}
                            sx={{"alignItems": "center"}}
                            marginTop={2}
                            justifyContent={"center"}
                        >
                            <TextField id="filled-basic" label="Job # or PO #" variant="filled" onChange={(event) => setInput(event.target.value)} onKeyDown={keyPress} value={input} />
                            <Button variant="contained" onClick={() => {addPO(input); setInput("")}}>ADD</Button>
                        </Stack>
                    </CardContent>
                </Card>
                { error && <Alert severity="error">{error}</Alert> }
                
                <Card sx={{minWidth: "400px"}}>
                    <List dense sx={{ width: '100%' }}>
                        <CardContent>
                            <Typography variant="h6">Purchase Orders</Typography>
                        </CardContent>
                        {docData.pos.map((po) => {
                            const name = po["job_number"] + "-" + po["number"];
                            const labelId = `checkbox-list-secondary-label-${name}`;
                            return (
                                <ListItem
                                    key={name}
                                    secondaryAction={
                                        <Stack
                                            direction={'row'}
                                        >
                                            <IconButton onClick={() => refreshPO(name)}>
                                                <Refresh />
                                            </IconButton>
                                            <IconButton href={`https://www.ateasesystems.net/PurchaseOrder/Details/${po.id}?jobId=${po.job_number}`} target={"_blank"}>
                                                <ExitToApp />
                                            </IconButton>
                                        </Stack>
                                    }
                                    disablePadding
                                >
                                    <ListItemButton role={undefined} onClick={handleToggle(name)} dense>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={docData.unchecked.indexOf(name) === -1}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText id={labelId} primary={`${name} (${po.supplier.name})`} />
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                    </List>
                    {
                        docData.pos.length > 0 &&
                        <Box display={"flex"} justifyContent={"center"} marginBottom={1}>
                            <Button variant={"text"} onClick={() => clearPOs()} >CLEAR ALL</Button>
                        </Box>
                    }
                </Card>
                {
                    purchaseLists.map((pl, idx) =>                    
                        <TableContainer key={idx} component={Paper}>
                            <Stack
                                direction={"row"}
                                justifyContent={"space-between"}
                            >
                                <Typography variant="h6" margin={2}>{pl.supplier.name}</Typography>
                                <IconButton onClick={() => copyToClipboard(pl)} sx={{width: "64px"}}>
                                    <ContentCopy />
                                </IconButton>
                                {/* <IconButton onClick={() => validatePO(pl)} sx={{width: "64px"}}>
                                    <Check />
                                </IconButton> */}
                            </Stack>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Sku</TableCell>
                                        <TableCell>Product Name</TableCell>
                                        <TableCell>Color</TableCell>
                                        <TableCell>Size</TableCell>
                                        <TableCell align="right">Price</TableCell>
                                        <TableCell align="right">Quantity</TableCell>
                                        <TableCell>POs</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {pl.items.map((item, idx) => (
                                        <TableRow
                                            key={idx}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {item.sku}
                                            </TableCell>
                                            <TableCell>{item.productName}</TableCell>
                                            <TableCell>{item.color}</TableCell>
                                            <TableCell>{item.size}</TableCell>
                                            <TableCell align="right">${item.price.toFixed(2)}</TableCell>
                                            <TableCell align="right">{item.quantity}</TableCell>
                                            <TableCell>
                                            <Stack direction="row" spacing={1}>
                                                {
                                                    item.pos?.map((po, idx) => (
                                                        <Tooltip key={idx} title={po.quantity}>
                                                            <Chip label={`${po.jobNumber}-${po.poNumber}`} component={"a"} onClick={() => {}} href={`https://www.ateasesystems.net/PurchaseOrder/Details/${po.poNumber}?jobId=${po.jobNumber}`} target={"_blank"}/>
                                                        </Tooltip>
                                                    ))
                                                }
                                            </Stack>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell colSpan={4} />
                                        <TableCell align="right">${pl.items.reduce((acc, i) => acc += i.price * i.quantity, 0).toFixed(2)}</TableCell>
                                        <TableCell align="right">{pl.items.reduce((acc, i) => acc += i.quantity, 0)}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                }
                {
                    myCAPOs.map((po, idx) => {
                        return (
                            <TableContainer key={idx} component={Paper} sx={{backgroundColor: red["50"]}}>
                                <Stack
                                    direction={"row"}
                                    justifyContent={"space-between"}
                                >
                                    <Typography variant="h6" margin={2}>{`${po.supplier} (${po.name})`}</Typography>
                                    {/* <IconButton onClick={() => copyToClipboard(pl)} sx={{width: "64px"}}>
                                        <ContentCopy />
                                    </IconButton> */}
                                </Stack>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Image</TableCell>
                                            <TableCell>Sku</TableCell>
                                            <TableCell>Product Name</TableCell>
                                            <TableCell>Color</TableCell>
                                            <TableCell>Size</TableCell>
                                            <TableCell align="right">Quantity</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.entries(po.poItems).map((entry, idx) => (
                                            <TableRow
                                                key={idx}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row" sx={{padding: "8px", display: "flex", justifyContent: "center"}}>
                                                    <img src={entry[1].imageURL} style={{maxHeight: "48px", maxWidth: "48px"}} />
                                                </TableCell>
                                                <TableCell>{entry[0].split("_")[0]}</TableCell>
                                                <TableCell>{entry[1].name}</TableCell>
                                                <TableCell>{entry[1].colorName}</TableCell>
                                                <TableCell>{entry[1].sizeName}</TableCell>
                                                <TableCell align="right">{po.items[entry[0]]?.toString() ?? 0}</TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <TableCell colSpan={5} />
                                            <TableCell align="right">{Object.values(po.items).reduce((acc, i) => acc += i, 0)}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        );
                    })
                }
                <Box height={"200px"}></Box>
            </Stack>
        </Container>
    );
}

export default POManagerPage;